<template>
  <el-popover
    ref="popoverRef"
    placement="bottom-start"
    :width="180"
    trigger="click"
    :offset="5"
    popper-class="elv-financials-cell-fromTo-popper"
    :disabled="!isUnnamedContactIdentity || !quickCreationCounterparty"
    @show="isFocus = true"
    @hide="isFocus = false"
  >
    <ul class="elv-financials-cell-fromTo-dropdown">
      <template v-if="!props.params?.data?.counterparty?.name">
        <li class="elv-financials-cell-fromTo-dropdown__item" @click="onCreateNewCounterparty">
          <SvgIcon name="add-default" width="16" height="16" fill="#7596EB" /> {{ t('button.createNewCounterparty') }}
        </li>
        <li class="elv-financials-cell-fromTo-dropdown__item" @click="onAddToCounterparty('add')">
          <SvgIcon name="add-default" width="16" height="16" fill="#7596EB" /> {{ t('button.addToCounterparty') }}
        </li>
      </template>
      <template v-else>
        <li class="elv-financials-cell-fromTo-dropdown__item" @click="onViewDetail">
          <SvgIcon name="order-details" width="16" height="16" fill="#7596EB" /> {{ t('button.viewDetail') }}
        </li>
        <li class="elv-financials-cell-fromTo-dropdown__item" @click="onAddToCounterparty('edit')">
          <SvgIcon name="change-catalog" width="16" height="16" fill="#7596EB" /> {{ t('button.changeCounterparty') }}
        </li>
      </template>
    </ul>
    <template #reference>
      <div
        v-if="isToType || isFromType"
        class="elv-financials-cell-fromTo-item-main"
        :data-type="props.type"
        :class="{ 'is-unnamed-contact': isUnnamedContactIdentity, 'is-focus': isFocus }"
      >
        <template
          v-if="
            currentLogo.logo !== undefined &&
            ((isToType &&
              ((props.params?.data?.direction === 'OUT' && props.params?.data?.contactIdentity) ||
                (props.params?.data?.direction === 'IN' &&
                  props.params?.data?.entityAccount?.name &&
                  props.params?.data?.platform?.logo) ||
                isFeeType)) ||
              (isFromType &&
                ((props.params?.data?.direction === 'OUT' && props.params?.data?.entityAccount?.name) ||
                  (props.params?.data?.direction === 'IN' && hasContactIdentity))) ||
              isExchangeTrade ||
              (props.params?.data?.type === 'EXCHANGE_FUNDING_FEE' && props.params?.data?.direction === 'IN'))
          "
        >
          <template v-if="currentLogo.type === 'img'">
            <img
              v-if="currentLogo.logo !== ''"
              :onerror="useDefaultImage"
              :src="currentLogo.logo"
              class="elv-financials-cell-fromTo-item-main__logo"
              :data-type="props.type"
            />
            <img
              v-else-if="props.params?.data?.contactPlatformId === null && hasContactIdentity"
              src="@/assets/img/default-counterparty-logo.png"
              alt="logo"
              class="elv-financials-cell-fromTo-item-main__logo"
            />
            <img
              v-else
              :onerror="useDefaultImage"
              src="@/assets/img/default-logo.png"
              class="elv-financials-cell-fromTo-item-main__logo"
              :data-type="props.type"
            />
          </template>

          <SvgIcon
            v-else
            :name="currentLogo.logo"
            :data-type="props.type"
            width="14"
            height="14"
            class="elv-financials-cell-fromTo-item-main__logo"
          />
        </template>

        <div
          class="elv-financials-cell-fromTo-main__address"
          :data-type="props.type"
          :title="currentText.content"
          :class="{ 'elv-financials-cell-fromTo-main__address-ellipsis': currentText.content.length > 15 }"
          :style="{ fontFamily: currentText.font }"
        >
          {{ currentText.content }}
        </div>
        <div v-if="isExchangeTrade && !isYou" class="elv-financials-cell-fromTo-main__fee">TRADE</div>
        <div v-else-if="isFXType && !isYou && !isFeeType" class="elv-financials-cell-fromTo-main__fee">FX</div>
        <div
          v-else-if="
            isToType &&
            isFeeType &&
            ((props.params?.data?.type === 'EXCHANGE_FUNDING_FEE' && props.params?.data?.direction !== 'IN') ||
              props.params?.data?.type !== 'EXCHANGE_FUNDING_FEE')
          "
          class="elv-financials-cell-fromTo-main__fee"
        >
          FEE
        </div>
        <div v-else-if="!isYou && hasInputs && !isFeeType && isFromType" class="elv-financials-cell-fromTo-main__fee">
          {{ props.params?.data?.inputs.length }}
        </div>
        <div
          v-else-if="isYou || props.params?.data?.internalEntityAccount?.name"
          class="elv-financials-cell-fromTo-main__you"
        >
          YOU
        </div>
      </div>
    </template>
  </el-popover>
</template>
<script lang="ts" setup>
import { formatInputAddress } from '@/lib/utils'
import defaultImg from '@/assets/img/default-logo.png'

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  },
  type: {
    type: String,
    required: true
  },
  quickCreationCounterparty: {
    type: Boolean,
    default: false
  }
})
const { t } = useI18n()

const isFocus = ref(false)
const popoverRef = ref()

const emit = defineEmits(['onViewDetail', 'onCreateNewCounterparty', 'onAddToCounterparty'])

const isToType = computed(() => {
  return props.type === 'to'
})

const isFromType = computed(() => {
  return props.type === 'from'
})

const isFeeType = computed(() => {
  const type = props.params?.data?.type
  return [
    'CHAIN_TRANSACTION_FEE',
    'BANK_FEE',
    'EXCHANGE_FEE',
    'PAYMENT_FEE',
    'CUSTODY_FEE',
    'EXCHANGE_FUNDING_FEE'
  ].includes(type)
})

const isFXType = computed(() => {
  const type = props.params?.data?.type
  return ['BANK_INCOMING_FX', 'BANK_OUTGOING_FX', 'PAYMENT_OUTGOING_FX', 'PAYMENT_INCOMING_FX'].includes(type)
})

const hasContactIdentity = computed(() => {
  return props.params?.data?.contactIdentity
})

const isExchangeTrade = computed(() => {
  const type = props.params?.data?.type
  return type === 'EXCHANGE_TRADE_OUT' || type === 'EXCHANGE_TRADE_IN'
})

const isYou = computed(() => {
  const direction = props.params?.data?.direction
  const name = props.params?.data?.entityAccount?.name
  return (isToType.value && direction === 'IN' && name) || (isFromType.value && direction === 'OUT' && name)
})

const hasInputs = computed(() => {
  return !!props.params?.data?.inputs?.length && !isFeeType.value
})

const isUnnamedContactIdentity = computed(() => {
  if (isYou.value || isFeeType.value || isExchangeTrade.value || props.params?.data?.internalEntityAccount?.name)
    return false
  return hasContactIdentity.value
})

const currentLogo = computed(() => {
  const data: any = {
    logo: '',
    name: '',
    type: 'img'
  }
  if (isExchangeTrade.value && !hasContactIdentity.value && !props.params?.data?.counterparty?.name) {
    data.logo = props.params?.data?.platform?.logo ?? props.params?.data?.source?.platform?.logo
    data.name = props.params?.data?.platform?.name
    return data
  }
  switch (props.type) {
    case 'from':
      if (props.params?.data?.direction === 'OUT') {
        data.logo = props.params?.data?.platform?.logo ?? props.params?.data?.source?.platform?.logo
        data.name = props.params?.data?.platform?.name
      } else {
        // eslint-disable-next-line no-nested-ternary
        data.logo = props.params?.data?.contactPlatform?.logo
          ? props.params?.data?.contactPlatform?.logo
          : hasContactIdentity.value
            ? ''
            : (props.params?.data?.platform?.logo ?? undefined)
        data.name = props.params?.data?.contactPlatform?.name
      }
      break
    default:
      if (props.params?.data?.direction === 'IN') {
        data.logo = props.params?.data?.platform?.logo ?? props.params?.data?.source?.platform?.logo
        data.name = props.params?.data?.platform?.name
      } else {
        // eslint-disable-next-line no-nested-ternary
        data.logo = props.params?.data?.contactPlatform?.logo
          ? props.params?.data?.contactPlatform?.logo
          : hasContactIdentity.value
            ? ''
            : (props.params?.data?.platform?.logo ?? undefined)
        data.name = props.params?.data?.contactPlatform?.name
      }
      break
  }
  return data
})

const currentText = computed(() => {
  const data = {
    content: '',
    font: 'Plus Jakarta Sans' // 'Barlow'
  }
  switch (props.type) {
    case 'from':
      if (props.params?.data?.entityAccount && props.params?.data?.direction === 'OUT') {
        data.content =
          props.params?.data?.entityAccount?.name !== ''
            ? props.params?.data?.entityAccount?.name
            : formatInputAddress(props.params?.data?.entityAccount?.identity)
        data.font = props.params?.data?.entityAccount?.name ? 'Plus Jakarta Sans' : 'Barlow'
      } else if (props.params?.data?.type === 'EXCHANGE_FUNDING_FEE' && props.params?.data?.direction === 'IN') {
        data.content = props.params?.data?.platform?.name ?? props.params?.data?.source?.platform?.name
      } else if (props.params?.data?.internalEntityAccount?.name && props.params?.data?.direction === 'IN') {
        data.content =
          props.params?.data?.internalEntityAccount?.name ?? formatInputAddress(props.params?.data?.contactIdentity)
        data.font = props.params?.data?.internalEntityAccount?.name ? 'Plus Jakarta Sans' : 'Barlow'
      } else if (hasInputs.value && !props.params?.data?.counterparty?.name) {
        data.content = 'Multi Addresses'
      } else if (props.params?.data?.counterparty?.name && props.params?.data?.direction === 'IN') {
        data.content =
          props.params?.data?.counterparty && props.params?.data?.counterparty?.name !== ''
            ? props.params?.data?.counterparty?.name
            : formatInputAddress(props.params?.data?.contactIdentity)
        data.font =
          props.params?.data?.counterparty && props.params?.data?.counterparty?.name ? 'Plus Jakarta Sans' : 'Barlow'
      } else if (props.params?.data?.contactIdentity) {
        data.content = formatInputAddress(props.params?.data?.contactIdentity)
        data.font = 'Barlow'
      } else if (isExchangeTrade.value) {
        data.content = props.params?.data?.platform?.name ?? props.params?.data?.source?.platform?.name
      } else {
        data.content = '-'
      }
      break
    default:
      if (props.params?.data?.entityAccount && props.params?.data?.direction === 'IN') {
        data.content =
          props.params?.data?.entityAccount?.name !== ''
            ? props.params?.data?.entityAccount?.name
            : formatInputAddress(props.params?.data?.entityAccount?.identity)
        data.font = props.params?.data?.entityAccount?.name ? 'Plus Jakarta Sans' : 'Barlow'
      } else if (isFeeType.value) {
        data.content = props.params?.data?.contactPlatform?.name ?? props.params?.data?.platform?.name
      } else if (props.params?.data?.internalEntityAccount?.name && props.params?.data?.direction === 'OUT') {
        data.content =
          props.params?.data?.internalEntityAccount?.name ?? formatInputAddress(props.params?.data?.contactIdentity)
        data.font = props.params?.data?.internalEntityAccount?.name ? 'Plus Jakarta Sans' : 'Barlow'
      } else if (props.params?.data?.counterparty?.name && props.params?.data?.direction === 'OUT') {
        data.content =
          props.params?.data?.counterparty && props.params?.data?.counterparty?.name !== ''
            ? props.params?.data?.counterparty?.name
            : formatInputAddress(props.params?.data?.contactIdentity)
        data.font =
          props.params?.data?.counterparty && props.params?.data?.counterparty?.name ? 'Plus Jakarta Sans' : 'Barlow'
      } else if (props.params?.data?.contactIdentity) {
        data.content = formatInputAddress(props.params?.data?.contactIdentity)
        data.font = 'Barlow'
      } else if (isExchangeTrade.value) {
        data.content = props.params?.data?.platform?.name ?? props.params?.data?.source?.platform?.name
      } else {
        data.content = '-'
      }
      break
  }
  return data
})

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}

const onCreateNewCounterparty = () => {
  nextTick(() => {
    popoverRef.value?.hide()
    emit('onCreateNewCounterparty')
  })
}

const onViewDetail = () => {
  nextTick(() => {
    popoverRef.value?.hide()
    emit('onViewDetail')
  })
}

const onAddToCounterparty = (type: string) => {
  nextTick(() => {
    popoverRef.value?.hide()
    emit('onAddToCounterparty', type)
  })
}
</script>
<style lang="scss" scoped>
.elv-financials-cell-fromTo-item-main {
  cursor: pointer;
  background: #f9fafb;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  border-radius: 12px;
  border: 1px solid #edf0f3;

  &.is-focus {
    border: 1px solid #dde1e6;
    background: #edf0f3;
  }

  .elv-financials-cell-fromTo-item-main__logo {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    display: block;
  }

  .elv-financials-cell-fromTo-main__address {
    height: 16px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $elv-theme-base-text-color;
    padding-left: 4px;

    &.elv-financials-cell-fromTo-main__address-ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 150px;
    }
  }

  .elv-financials-cell-fromTo-main__fee {
    display: flex;
    padding: 1px 4px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background: #edf0f3;
    color: #ff754e;
    font-family: 'Barlow';
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 4px;
  }

  .elv-financials-cell-fromTo-main__you {
    display: flex;
    padding: 1px 4px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background: #edf0f3;
    color: #1753eb;
    font-family: 'Barlow';
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 4px;
  }
}
</style>
<style lang="scss">
.elv-financials-cell-fromTo-popper.el-popper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  border: none;
  background: #fff;
  padding: 0px;
  box-shadow:
    0px 2px 6px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.3);

  .elv-financials-cell-fromTo-dropdown {
    width: 100%;
  }

  .elv-financials-cell-fromTo-dropdown__item {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 8px;
    align-items: center;
    gap: 4px;
    height: 36px;
    align-self: stretch;
    color: #2f63eb;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

    &:hover {
      background-color: #f9fafb;
    }
  }
}
</style>
